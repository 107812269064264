import React, { useState, useEffect } from "react";
import { VesselMachineryList } from "../VesselConfiguration.model";
import apiGlobal from "../../../global/api.global";
import { useSelector } from "react-redux";
import { RootState } from "../../../";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    Input,
    Row,
} from "reactstrap";
import { errorToast, successToast } from "../../../Components/Toasts";
import { errResponse } from "GenericForms/Helper";
import { loadReeferContainerWithkWh } from "VesselMaster/vesselMaster.hooks";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";

const ReeferContainer = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /** Queries */
    /** Load Reefer Container with KWH machinaries */
    const {
        data: ReeferContainer,
        isLoading: ReeferContainerLoading,
        isError: ReeferContainerError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.ReeferContainerWithkWhMachineries.key, VesselID],
        async () => {
            return await loadReeferContainerWithkWh(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** Assign initial values to Reefer Container formik object */
    const getReeferContainerInitailValues = () => {
        if (ReeferContainer?.length > 0) {
            return ReeferContainer;
        }
    };

    /** Reefer Container Formik Object */
    const ReeferContainerFormik = {
        initialValues: {
            reeferContainer: getReeferContainerInitailValues(),
        },
        validationSchema: {},
    };

    return (
        <>
            <Card className="border-0">
                {ReeferContainerLoading && <Loading message="Loading required data!" />}
                {ReeferContainerError && (
                    <ErrorComponent message="Error loading required data!" />
                )}
                {!ReeferContainerLoading && !ReeferContainerError && (
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            values?.reeferContainer?.map((val: any) => {
                                apiGlobal
                                    .put(`/special_operation_energy_setting/${val.id}/`, val)
                                    .then((res) => {
                                        if (res.status === 200) {
                                            successToast("Data saved successfully!");
                                        }
                                    })
                                    .catch((err) => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast(
                                                "Internal error occured, please contact the admin"
                                            );
                                        }
                                    });
                            });
                        }}
                        initialValues={ReeferContainerFormik.initialValues}
                    >
                        {(props) => (
                            <Form
                                onSubmit={props?.handleSubmit}
                                noValidate
                                autoComplete="off"
                            >
                                <CardBody className="p-0">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="p-2 text-center align-middle sr-no-width">
                                                                #
                                                            </th>
                                                            <th className="p-2 align-middle">
                                                                Machinery name
                                                            </th>
                                                            <th className="p-2 align-middle text-center">
                                                                Is kWh meter fitted on power pack/electrical
                                                                motor panel?
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name="reeferContainer">
                                                            {() => (
                                                                <>
                                                                    {props?.values &&
                                                                        props?.values?.reeferContainer?.map(
                                                                            (reeferContainer: any, index: number) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="p-2 align-middle text-center">
                                                                                            {index + 1}
                                                                                        </td>
                                                                                        <td className="p-2 align-middle">
                                                                                            {
                                                                                                reeferContainer.vessel_machinery_ec_group_name
                                                                                            }
                                                                                        </td>
                                                                                        <td className="p-2 align-middle text-center">
                                                                                            <Field
                                                                                                name={`reeferContainer.${index}.reefer_container_kwh_meter`}
                                                                                            >
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        id={`reeferContainer.${index}.reefer_container_kwh_meter`}
                                                                                                        name={field.name}
                                                                                                        {...field}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="p-2 py-3 mb-3">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="justify_right"
                                    >
                                        Save
                                    </Button>
                                </CardFooter>
                                <Row className="mt-2">
                                    <FormValuesDebug
                                        values={[
                                            props?.values,
                                            props?.errors,
                                            ReeferContainerFormik.initialValues,
                                        ]}
                                    />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card>
        </>
    );
};
export default ReeferContainer;
